import React from "react"

import Layout from "../components/layout"
import { SEO } from "../components/seo"

const Page = () => {
  return (
    <Layout>
      <SEO
        title={`Nici`}
        description={`Nici PDO tworzą pod skórą rusztowanie dla zwiotczałej skóry bez konieczności zwiększania objętości. Stosowane podczas zabiegu rozpuszczalne nici PDO doskonale wpływają na kondycję i napięcie skóry, przynoszą natychmiastowy i długotrwały efekt.`}
      />

      <div className="offer-item">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-xs-12">
              <h1 className="offer-item-title">Nici</h1>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Nici PDO tworzą pod skórą rusztowanie dla zwiotczałej skóry bez
                konieczności zwiększania objętości. Stosowane podczas zabiegu
                rozpuszczalne nici PDO doskonale wpływają na kondycję i napięcie
                skóry, przynoszą natychmiastowy i długotrwały efekt. Jest to
                spektakularny zabieg, który łączy rewitalizację skóry na
                poziomie komórkowym z niwelowaniem zmarszczek i delikatnym
                liftingiem.
              </p>
              <p className="offer-item-description">
                Nici PDO umieszczone są na sterylnych, bardzo cienkiej igłach,
                przy pomocy których wprowadza się je w określone partie twarzy
                lub ciała . Technika ich aplikacji opiera się na wykorzystaniu
                naturalnych linii napięcia skóry, co dodatkowo powoduje efekt
                zbliżony do liftingu chirurgicznego. W zależności od miejsca
                podania, nici PDO wprowadzane są śródskórnie, lub do podskórnej
                tkanki tłuszczowej. Aplikowane głębiej poprawiają strukturę
                skóry – staje się ona grubsza, bardziej napięta, jędrna, poprawa
                się jej koloryt, a pory zwężają się. Można nimi również
                wygładzić drobne zmarszczki. Różna jest także długość nici.
                Krótsze sprawdzają się idealnie wokół oczu jako alternatywa dla
                botoksu – efekt estetyczny jest bardziej naturalny, niż po
                podaniu toksyny botulinowej, ponieważ mięśnie mimiczne nie
                zostają unieruchomione, a jedynie rozluźnione, a co za tym idzie
                – rysy twarzy wygładzają się. Dzięki dłuższym niciom PDO można
                otrzymać efekt delikatnego liftingu żuchwy . Nici PDO
                rozpuszczają się do 3-4 miesięcy, w ich miejsce powstaje nowo
                utworzony kolagen. Nici świetnie sprawdzą się także doskonale u
                pacjnetów którzy chcą poprawić jakość skóry bez wypełniania jej
                kwasem.
              </p>
              <p className="offer-item-description">
                Nici haczykowe - najgrubsze z nici PDO , mające dodatkowo
                haczyki, dzięki którym po prawidłowej implantacji możemy
                podnieść nadmiar tkanek .( chomiki)
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">Zastosowanie nici</h2>
              <div className="separator-line-horizontal"></div>
              <div className="offer-item-description">
                <ul>
                  <li>
                    TWARZ: pionowe i poziome zmarszczki na czole, lifting czoła,
                    obniżone zewnętrzne krawędzie brwi, wiotka, miękka tkanka,
                    dolna powieka – worki, policzki, wyraźny fałd
                    nosowo-wargowy, rowek policzkowo-jarzmowy, fałd podbródkowy,
                    fałd marionetkowy, V-lifting, opadające policzki i obszar
                    pod dolną szczęką, fałdy skóry na policzku, pomarszczone
                    policzki
                  </li>
                  <li>
                    CIAŁO: fałdy i zmarszczki skóry na szyi i dekolcie,
                    ujędrnianie i kształtowanie piersi,
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">Przeciwwskazania do zabiegu</h2>
              <div className="separator-line-horizontal"></div>
              <div className="offer-item-description">
                <ul>
                  <li>stany zapalne skóry i tkanki podskórnej</li>
                  <li>ciąża/laktacja</li>
                  <li>choroby autoimmunologiczne</li>
                  <li>
                    zrosty i zwłóknienia w obrębie skóry i tkanek podskórnych
                  </li>
                  <li>padaczka</li>
                  <li>choroby psychiczne</li>
                  <li>zaburzenia krzepliwości krwi</li>
                  <li>
                    stosowanie kuracji antykoagulantami (kurację należy
                    zaprzestać na minimum 14 dni przed planowanym zabiegiem)
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-12 col-xs-12">
              <p className="offer-item-title">Przebieg zabiegu </p>
              <div className="separator-line-horizontal"></div>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">
                Implantacja nici rewitalizujących (Mono, screw)
              </h2>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                nie wymaga specjalnych przygotowań. Po nałożeniu kremu
                znieczulajacego lekarz wprowadza nici w skórę. Niekiedy mogą
                wystąpić drobne siniaczki . Zabieg nie wymaga rekonwalescencji.
                Nie wyłącza z życia codziennego.
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">Implantacja nici haczykowych</h2>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                zabieg wymaga dobrego znieczulenia ( artekaina, lignocaina) , i
                sterylnych warunków, po znieczuleniu lekarz wprowdza nić
                zamieszczoną na grubszej igle ( miejsce wklucia w okolicy
                skroni, przed uchem), następnie „naciąga” do góry tkanki,
                „układa” niejako skóre policzka a następnie i ucina nadmiar
                wystającej nici . B ezpośrednio po zabiegu w okolicy uch amoże
                wystąpić pomarszczenie skóry , z namiaru naciągniętej tkanki,
                jest to efekt przejściowy ( do 2 tygodni). Po upływie tego czasu
                skóra rozprostowuje się i wyładza . Miejsca wkłucia
                zabezpieczamy majścią z antybiotykiem.
              </p>
              <div className="offer-item-description">
                Po zabiegu 2-3 dni unikamy:
                <ul>
                  <li>
                    nakładania w miejsce wkłucia podkładu, fluidu czy innych
                    kosmetyków - skórę odkażamy, nie dotykamy.
                  </li>
                  <li>
                    zabiegów dentystycznych - szerokiego otwierania buzi
                    (gryzienia np. jabłek i innych dużych owoców)
                  </li>
                  <li>wysiłku fizycznego</li>
                  <li>basenu, sauny, solarium - picia alkoholu</li>
                </ul>
              </div>
              <p className="offer-item-description">
                Ostrożność zachowujemy do całkowitego zagojenia się miejsca
                wkłucia , które przy zaniechaniu środków ostrożności może być po
                prostu wrotami dla bakterii, które mogą wniknąć i spowodować
                głębokie niebezpieczne zakażenie!!!
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
